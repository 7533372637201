:root {
  --primary-color: #f3f2f0;
  --secondary-color: #b38b6d;
  --tertiary-color: #403e3e;
}

* {
  margin: 0;
  padding: 0;
}

main {
  margin-bottom: 20px;
}



/* Navigation */

.nav-container {
  width: 100%;
  height: 40px;
  background-color: var(--secondary-color);
}

.nav-list-item {
  list-style: none;
  margin: 25px;
}

.nav-list-item a {
  color: white;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
}

.nav-list-item a:hover {
  color: var(--tertiary-color);
  transition: all 0.35s ease-in-out;
}






/* Header */

.header-container {
  background-color: var(--primary-color);
}

.header-title {
  font-family: 'Cormorant Garamond', serif;
  text-decoration: none;
  font-size: 60px;
  color: var(--secondary-color);
}

.header-title:hover {
  color: #debda3;
  transition: all 0.30s ease-in-out;
}

.header-subtitle {
  font-family: 'Cormorant Garamond', serif;
  font-size: 25px;
  color: var(--tertiary-color);
}



/* Footer */

.footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  background-color: var(--primary-color);
  width: 100%;
}

.icon {
  height: 25px;
  width: auto;
  margin: 10px;
}




/* About Page */

.section-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  background-color: var(--primary-color);
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.section-1-title {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 55px;
  color: var(--tertiary-color);
}

.section-1-subtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: var(--tertiary-color);
  margin-left: 20px;
}

.section-1-text {
  width: 75%;
}

.section-1-text-content {
  width: 90%;
  height: auto;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  color: var(--tertiary-color);
}

.section-1-image {
  height: 300px;
  width: auto;
  border: 10px solid white;
}

.section-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  background-color: white;
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 3%;
  padding-left: 10%;
}

.section-2-title {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: var(--tertiary-color);
}

.section-2-text {
  width: 60%;
  margin-left: 80px;
}

.section-2-text-content {
  width: 90%;
  height: auto;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  color: var(--tertiary-color);
}

.section-2-image {
  height: 300px;
  width: auto;
  border: 10px solid white;
  opacity: .85;
}

.section-3 {
  height: 350px;
  background-color: var(--secondary-color);
  width: 100%;
  padding: 10%;
}

.section-3-title {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: white;
}

.section-3-text {
  width: 75%;
  text-align: center;
}

.section-3-text-content {
  width: 90%;
  height: auto;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  color: white;
}

.hobbies-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hobby-group {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
}

.hobby-icon {
  height: 80px;
  width: auto;
  margin-top: 30px;
}

.hobby-title {
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  text-align: center;
  color: white;
  margin-top: 10px;
}

.section-4 {
  height: 350px;
  background-color: white;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.section-4-title {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: var(--tertiary-color);
}

.section-4-text {
  width: 60%;
  text-align: center;
}

.section-4-text-content {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 50px;
  color: var(--tertiary-color);
  margin-top: 15px;
}

.section-4-image {
  height: 150px;
  width: auto;
}







/* Portfolio Page */

.projects-section {
  width: 100vw;
  height: auto;
  min-height: 100vh;
}

.projects-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  transform: translateY(80px);
  padding: 0;
  height: auto;
}

.project-cell-container {
  text-decoration: none;
}

.project-cell {
  flex: 0 1 250px;
  max-width: 250px;
  height: 275px;
  margin: 2px;
  position: relative;
  text-align: center;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
}

.project-cell-image {
  object-fit: cover;
  object-position: center;
}

.project-cell-text {
  font-family: 'Raleway', sans-serif;
  color: var(--tertiary-color);
  opacity: 0;
  height: 100%;
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 1000;
  font-size: 1rem;
  transition: opacity 350ms;
}

.project-cell-description {
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
}

.project-cell:hover .project-cell-text {
  color: var(--tertiary-color);
  opacity: 1;
  z-index: 99;
}

.project-cell::before,
.project-cell::after,
.project-cell-image {
  top: -50%;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.project-cell:hover {
  opacity: 1;
  transition: all 0.35s ease-in-out;
  transform: scale(1.5);
  z-index: 99;
  overflow: hidden;
}

.project-cell:hover img {
  opacity: 0.15;
}






/* Resume Page */

.resume-page-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%
}

.skills-container {
  background-color: white;
  height: 600px;
  width: 50%;
}

.skills-title {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 60px;
  color: var(--tertiary-color);
  margin-top: 40px;
  margin-left: -20px;
}

.skills-list {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  color: var(--tertiary-color);
  list-style: none;
}

.skills-list li::before {
  content: "\2022";
  color: var(--secondary-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.resume-container {
  background-color: white;
  height: 600px;
  width: 50%;
}

.resume-container-inner {
  height: 450px;
  width: 450px;
  background-color: white;
  box-shadow: -10px 15px 5px rgba(0, 0, 0, 0.3);
}

.resume-container-inner-inner {
  height: 425px;
  width: 425px;
  background-color: white;
  border: 10px solid var(--primary-color);
  margin: 0px;
}

.resume-icon {
  height: 175px;
  width: auto;
  opacity: .80;
  box-shadow: -5px 10px 5px rgba(0, 0, 0, 0.15);
}

.resume-icon:hover {
  transform: scale(1.25);
  opacity: 1;
  transition: all 0.45s ease-in-out;
}

.resume-text {
  color: var(--tertiary-color);
  font-family: 'Cormorant Garamond', serif;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 0%;
  margin-bottom: 0px;
}






/* Contact Page */

.contact-container-inner {
  background-color: white;
  border: 10px solid var(--primary-color);
  height: auto;
  width: 500px;
  margin-top: 40px;
  margin-bottom: 60px;
  box-shadow: -10px 15px 5px rgba(0, 0, 0, 0.2);
}

.contact-title {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 80px;
  color: var(--tertiary-color);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 25px;
}

.subtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  color: var(--tertiary-color);
}

.call-text {
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  color: var(--secondary-color);
}

.input {
  width: 90%;
  margin-top: 15px;
  color: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.textarea {
  width: 90%;
  height: 200px;
  margin-bottom: 20px;
}

.send-button {
  background-color: var(--secondary-color);
  border: none;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  box-shadow: -5px 5px 2px rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 40px;
  padding: 5px;
  margin-bottom: 30px;
}

.send-button:hover {
  transition: all 0.35s ease-in-out;
  transform: scale(1.05);
  z-index: 99;
}

.error-text {
  color: var(--tertiary-color);
  font-style: italic;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
}











/* RESPONSIVE */

@media screen and (max-width: 550px) {

  .header-title {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    line-height: 1;
  }

  .section-1 {
    flex-direction: column;
    height: auto;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .section-1-text {
    width: 90%;
    margin-left: 10%;
    margin-bottom: 5%;
  }

  .section-2 {
    flex-direction: column;
    padding: 5%;
  }

  .section-2-text {
    width: 95%;
    text-align: left;
    margin-left: 5%;
    margin-top: 5%;
  }

  .section-3 {
    height: auto;
  }

  .hobbies-container {
    flex-direction: column;
  }

  .section-4 {
    height: auto;
    padding: 3%;
  }

  .section-4-title {
    margin-top: 10%;
    margin-bottom: 20%;
  }

  .section-4-image {
    height: 60px;
    width: auto;
  }

  .project-cell {
    position: relative;
    margin: 25px 25px;
  }

  .resume-page-container {
    flex-direction: column;
  }

  .skills-container {
    height: auto;
    width: 100%;
    border: none;
  }

  .skills-title {
    font-size: 55px;
    text-align: center;
  }

  .skills-list {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .resume-container {
    width: 100%;
  }

  .resume-container-inner {
    width: 325px;
  }

  .resume-container-inner-inner {
    width: 300px;
  }

  .contact-container-inner {
    width: 350px;
  }
}


@media screen and (min-width: 550px) and (max-width: 825px) {

  .header-title {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    line-height: 1;
  }

  .section-1 {
    height: auto;
    padding: 5%;
  }

  .section-2 {
    flex-direction: column;
    padding: 5%;
  }

  .section-2-text {
    width: 85%;
    text-align: left;
    margin-left: 5%;
    margin-top: 5%;
  }

  .section-3 {
    height: auto;
  }

  .hobbies-container {
    flex-direction: column;
  }

  .section-4 {
    height: auto;
    padding: 3%;
  }

  .section-4-title {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .section-4-image {
    height: 70px;
    width: auto;
  }

  .project-cell-container:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .project-cell-container:nth-child(3) {
    position: relative;
    top: -70px;
  }

  .project-cell-container:nth-child(4) {
    position: relative;
    top: -140px;
  }

  .project-cell-container:nth-child(5) {
    position: relative;
    top: -140px;
  }

  .project-cell-container:nth-child(6) {
    position: relative;
    top: -210px;
  }

  .project-cell-container:nth-child(7) {
    position: relative;
    top: -280px;
    margin-right: 250px;
  }

  .resume-page-container {
    flex-direction: column;
  }

  .skills-container {
    width: 100%;
    height: auto;
    border: none;
  }

  .skills-title {
    font-size: 55px;
    text-align: center;
  }

  .skills-list {
    font-size: 16px;
    margin-bottom: 10%;
  }

  .resume-container {
    width: 100%;
  }

  .contact-container-inner {
    width: 475px;
  }
}


@media screen and (min-width: 825px) and (max-width: 1100px) {

  .section-2 {
    height: auto;
    padding: 5%;
  }

  .section-2-text {
    width: 60%;
    margin-left: 25px;
  }

  .section-3 {
    padding: 3%;
  }

  .hobby-group {
    margin-left: 30px;
    margin-right: 30px;
  }

  .section-4 {
    padding-right: 5%;
    padding-left: 5%;
  }

  .section-4-text {
    width: 100%;
    text-align: center;
  }

  .section-4-text-content {
    font-family: 'Mrs Saint Delafield', cursive;
    font-size: 50px;
    color: var(--tertiary-color);
    margin-top: 15px;
  }

  .section-4-image {
    height: 100px;
    width: auto;
  }

  .project-cell-container:nth-child(4) {
    top: -70px;
    position: relative;
    margin-left: calc(50% - 275px);
  }

  .project-cell-container:nth-child(5) {
    top: -70px;
    position: relative;
    margin-right: calc(50% - 275px);
  }

  .project-cell-container:nth-child(6) {
    top: -140px;
    position: relative;
    margin-left: calc(50% - 530px);
  }

  .project-cell-container:nth-child(7) {
    top: -140px;
    position: relative;
    margin-right: calc(50% - 275px);
  }

  .resume-page-container {
    flex-direction: column;
  }

  .skills-container {
    width: 100%;
    height: auto;
    border: none;
  }

  .skills-title {
    text-align: center;
  }

  .resume-container {
    width: 100%;
  }
}


@media screen and (min-width: 1100px) {

  .nav-list-item {
    margin: 35px;
  }

  .nav-list-item a {
    font-size: 18px;
  }

  .header-title {
    font-size: 70px;
  }

  .header-subtitle {
    font-size: 30px;
  }

  .icon {
    margin: 20px;
  }

  .section-3 {
    padding: 3%;
  }

  .hobby-group {
    margin-left: 40px;
    margin-right: 40px;
  }

  .section-4 {
    padding-right: 12.5%;
    padding-left: 12.5%;
  }

  .section-4-text {
    width: 100%;
    text-align: center;
  }

  .section-4-text-content {
    font-family: 'Mrs Saint Delafield', cursive;
    font-size: 50px;
    color: var(--tertiary-color);
    margin-top: 15px;
  }

  .section-4-image {
    height: 100px;
    width: auto;
  }

  .project-cell-container:nth-child(n + 5) {
    position: relative;
    top: -70px;
  }

  .skills-container {
    height: 525px;
  }

  .skills-title {
    text-align: center;
  }

  .resume-container {
    height: 525px;
  }
}




@media screen and (min-width: 1600px) {

  .section-1 {
    padding-left: 20%;
  }
  
  .section-2 {
    padding-left: 20%;
  }

  .hobby-group {
    margin-left: 60px;
    margin-right: 60px;
  }

  .section-4 {
    padding-right: 25%;
    padding-left: 25%;
  }

  .section-4-text-content {
    font-size: 60px;
  }

  .resume-page-container {
    padding-left: 20%;
    padding-right: 20%;
  }
}