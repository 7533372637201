/* .projects-section {
  background-color: darkgray;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding-bottom: 8rem;
}

.projects-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  transform: translateY(80px);
  padding: 0;
  background-color: darkgray;
  height: auto;
}

.project-cell {
  flex: 0 1 250px;
  max-width: 250px;
  height: 275px;
  margin: 2px;
  position: relative;
  text-align: center;
  z-index: 1;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
}

.project-cell-image {
  object-fit: cover;
  object-position: center;
}

.project-cell-text {
  height: 100%;
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  font-weight: 1000;
  font-size: 1rem;
  transition: opacity 350ms;
  text-shadow: 5px 5px 5px black;
}

.project-cell::before,
.project-cell::after,
.project-cell-image {
  top: -50%;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.project-cell:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
  z-index: 99;
  overflow: hidden;
} */

/* .project-cell:before {
  content: '';
  position: absolute;
  opacity: 0.4;
  width: 350px;
  height: 70px;
  background: white;
  top: 50;
  left: 0;
  z-index: 1;
  transform: rotate(45deg);
  transition: transform 1.5s;
}

.project-cell:hover:before {
  transform: translate(-100px, 600%) rotate(45deg);
  transition: transform 0.5s;
} */












/* RESPONSIVE */

@media screen and (max-width: 550px) {

  .project-cell {
    margin: 25px 25px;
  }
}


@media screen and (min-width: 550px) and (max-width: 825px) {
  .project-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .project-cell:nth-child(3) {
    top: -70px;
  }

  .project-cell:nth-child(4) {
    top: -140px;
  }

  .project-cell:nth-child(5) {
    top: -140px;
  }

  .project-cell:nth-child(6) {
    top: -210px;
  }

  .project-cell:nth-child(7) {
    top: -280px;
    margin-right: 250px;
  }
}


@media screen and (min-width: 825px) and (max-width: 1100px) {
  .project-cell:nth-child(4) {
    top: -70px;
    margin-left: calc(50% - 275px);
  }

  .project-cell:nth-child(5) {
    top: -70px;
    margin-right: calc(50% - 275px);
  }

  .project-cell:nth-child(6) {
    top: -140px;
    margin-left: calc(50% - 530px);
  }

  .project-cell:nth-child(7) {
    top: -140px;
    margin-right: calc(50% - 275px);
  }
}


@media screen and (min-width: 1100px) {

  .project-cell:nth-child(n + 5) {
    position: relative;
    top: -70px;
  }
}

